import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styles from '../styles/components/footer-pst.module.scss';
import Icon from './base/icon';
import Tooltip from './base/tooltip';
import TooltipInfo from './base/tooltipInfo';

export default function FooterPst(props) {
  const currentDate = new Date();
  const t = useTranslations('Footer');

  return (
    <footer className={styles.footer}>
      <div className={styles.associateLogos}>
        <Image
          objectFit="contain"
          alt="RAP"
          src="/images/LG_RAP1.png"
          width="180px"
          height="100px"
          layout="fixed"
          loading="lazy"
        />
        <Image
          objectFit="contain"
          alt="Cauca"
          src="/images/LG_CAUCA1.png"
          width="100px"
          height="75px"
          layout="fixed"
          loading="lazy"
        />
        <Image
          alt="Choco"
          objectFit="contain"
          src="/images/LG_CHOCO1.png"
          width="100px"
          height="75px"
          layout="fixed"
          loading="lazy"
        />
        <Image
          alt="Nariño"
          objectFit="contain"
          src="/images/LG_NARINO1.png"
          width="100px"
          height="75px"
          layout="fixed"
          loading="lazy"
        />
        <Image
          alt="Valle"
          objectFit="contain"
          src="/images/LG_VALLE1.png"
          width="100px"
          height="75px"
          layout="fixed"
          loading="lazy"
        />
        <Image
          alt="SGR"
          objectFit="contain"
          src="/images/sgr.png"
          width="131px"
          height="62px"
          layout="fixed"
          loading="lazy"
        />
      </div>

      <div className={styles.middleSection}>
        <div className={styles.logoContainer}>
          <img
            className={styles.logo}
            src="/images/logo-pacifico-colombia.svg"
            alt=""
          />
        </div>

        <div className={styles.links}>
          <div className={styles.firstDiv}>
            <ul>
              <li>
                <Link href="/terminos-y-condiciones">
                  <a href="">
                    Términos, condiciones y <br /> política de privacidad
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/ayuda">
                  <a href="">Ayuda</a>
                </Link>
              </li>
              <li>
                <Link href="/negocios/informacion">
                  <a href="">Información</a>
                </Link>
              </li>
            </ul>
          </div>
          <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
          <div className={styles.secondDiv}>
            <ul>
              <li>
                <Link href="/experiencia">
                  <a href="">
                    Experiencias Realidad Virtual, <br /> Aumentada y Mixta
                  </a>
                </Link>
              </li>

              <li>
                <a href={process.env.NEXT_PUBLIC_SIG_FRONT_URL} target="_blank">
                  SIG
                </a>
              </li>
              <li>
                <TooltipInfo message="Ingresa al portal desde un computador o un portátil">
                  <a
                    href={process.env.NEXT_PUBLIC_PORTAL_IDEAS_INNOVADORAS}
                    target="_blank"
                  >
                    Portal de ideas innovadoras <br /> e ideas de negocio
                  </a>
                </TooltipInfo>
              </li>
            </ul>
          </div>
        </div>

        <div className={styles.appLinks}>
          <div>
            <p>Síguenos en:</p>
          </div>
          <nav className={styles.appLinks}>
            <ul>
              <li className={styles.facebook}>
                <Link href="">
                  <a href="">
                    Enlace a Facebook
                    <Icon icon="facebook" size={24} />
                  </a>
                </Link>
              </li>

              <li className={styles.youtube}>
                <Link href="">
                  <a ref="">
                    Enlace a YouTube
                    <Icon icon="youtube" size={24} />
                  </a>
                </Link>
              </li>

              <li className={styles.instagram}>
                <Link href="">
                  <a href="">
                    Enlace a Instagram
                    <Icon icon="instagram" size={24} />
                  </a>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className={styles.footerPreEnd}></div>
      <div className={styles.footerEnd}>
        <p>{`© ${t(
          'rights'
        )} ${currentDate.getFullYear()} - Turismo Región Pacífico`}</p>
      </div>
    </footer>
  );
}
