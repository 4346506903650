import { FunctionComponent } from 'react';
import styles from '../../styles/components/tooltip.module.scss';
import Icon from './icon';

type TooltipProps = {
  message: string;
};

const Tooltip: FunctionComponent<TooltipProps> = ({ message }) => {
  return (
    <div className={styles.tooltip}>
      <Icon icon="info" size={18} />
      <div className={styles.box}>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Tooltip;
