import styles from '../styles/components/password-strength-box.module.scss';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { validatePasswordStrength } from '../shared/utils';
import {useTranslations} from "next-intl";

export default function PasswordStrengthBox(props) {
  const [passwordClass, setPasswordClass] = useState(null);
  const [passwordText, setPasswordText] = useState('');
  const translations = useTranslations('TouristRegistration.From.AccountData.PasswordStrengthBox');

  const passwordStrengthWords = [
    translations('passwordStrengthWords.0'),
    translations('passwordStrengthWords.1'),
    translations('passwordStrengthWords.2'),
  ];

  useEffect(() => {
    const validationResult = validatePasswordStrength(props.password);

    if (validationResult <= 2) {
      setPasswordText(passwordStrengthWords[0]);
      setPasswordClass('weak');
      props.onPasswordValidation?.(false);
    } else if (validationResult == 3) {
      setPasswordText(passwordStrengthWords[1]);
      setPasswordClass('okay');
      props.onPasswordValidation?.(false);
    } else {
      setPasswordText(passwordStrengthWords[2]);
      setPasswordClass('strong');
      props.onPasswordValidation?.(true);
    }
  }, [props.password]);

  if (props.password === null || props.password === '') {
    return null;
  }

  return (
    <div className={styles.passwordStrengthContainer}>
      <div
        className={classNames([styles.passwordChecker, styles[passwordClass]])}
      >
        <span />
        <span />
        <span />
      </div>
      <p
        className={classNames([styles.passwordMessage, styles[passwordClass]])}
      >
        {passwordText}
      </p>
      <ul>
        <li>{translations('Rules.0')}</li>
        <li>{translations('Rules.1')}</li>
        <li>{translations('Rules.2')}</li>
        <li>{translations('Rules.3')}</li>
      </ul>
    </div>
  );
}
