import { FunctionComponent } from 'react';
import styles from '../../styles/components/tooltip.module.scss';
import Icon from './icon';

type TooltipProps = {
    message: string;
    children: JSX.Element;
  };

const TooltipInfo: FunctionComponent<TooltipProps> = ({ children,message }) => {
    return (
        <div className={styles.tooltip}>
          { children }
          <div className={styles.box}>
            <p>{message}</p>
          </div>
        </div>
      );
}

export default TooltipInfo;