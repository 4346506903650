import Link from "next/link";
import styles from "../../../styles/components/dropdown.module.scss";
import Icon from "../icon";

type DropDownItem = {
    children: any,
    iconRigth?: string,
    iconLeft: string,
    route: string,
    imgPath?: string
}


export default function DropdownItem({ children, iconLeft, iconRigth, route, imgPath }: DropDownItem) {
    return (
        <Link href={route}>
            <a className={styles.dropdownMenuItem}>
                <span className={styles.iconButton}> {imgPath ? <img src={imgPath} alt="" /> : <Icon className={styles.iconStyle} icon={iconLeft} size={23} />} </span>
                {children}
                <span className={styles.iconButtonRight}><img src={iconRigth} alt="" /></span>
            </a>
        </Link>
    )
}