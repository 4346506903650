import styles from "../../styles/components/sign-out-button.module.scss";
import Icon from "./icon";
import { useTranslations } from 'next-intl';
type SignOutButtonType = {

    setShowLogoutPopup : (arg:boolean) => void,

}

export default function SignOutButton({setShowLogoutPopup}:SignOutButtonType) {
    const t = useTranslations('Header');
    return <>

        <div className={""}>
            <button className={styles.signOut} onClick={() => setShowLogoutPopup(true)}>
                <Icon icon="sign-out" size={23} />
                <p>{t('cerrarSesion')}</p>
            </button>
        </div>

    </>
}