import styles from "../../../styles/components/dropdown.module.scss";
import DropdownItem from "./dropdown-item";

type OptiosMenu = {
    route: string,
    label: string,
    icon: string,
    img?: string,
}

type DropDownMenu = {
    children: any,
    options?: OptiosMenu[],
    className?: string;
}

export default function DropdownMenu({ children, options, className }: DropDownMenu) {

    return <>

        <div className={`${styles.dropdown} ${className}`} >
            {options?.map((element, index) => (
                <DropdownItem key={index} imgPath={element.img} iconLeft={element.icon} route={element.route} >{element.label}</DropdownItem>
            ))}
            {children}
        </div>

    </>
}