import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { apiRestGet, apiRestPost } from '../services/request';
import {
  getDepartmentByMunicipality,
  getDepartmentName,
  getImageURL,
  getMunicipalityName,
  getServiceURL,
  getTouristServiceTypeMuncipalityID,
  splitField,
} from '../shared/utils';

import HistorySearch from '../components/base/historySearch';
import styles from '../styles/components/big-search-box.module.scss';
import Icon from './base/icon';
import { GeneralConfigContext } from './general-config-provider';
import { useTranslations } from 'next-intl';
import { getCurrentUser, getCurrentUserRole } from '../services/cognito';

type BigSearchBoxProps = {
  isOpened: boolean;
  onBigSearchBoxClosed: any;
};

export const BigSearchBox: FunctionComponent<BigSearchBoxProps> = ({
  isOpened = false,
  onBigSearchBoxClosed,
}) => {
  const { generalConfig } = useContext(GeneralConfigContext) || {};
  const router = useRouter();

  const MIN_TERM_LENGTH_FOR_SEARCH = 3;
  const [searchTerm, setSearchTerm] = useState('');
  const [offersList, setOffersList] = useState([]);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [searching, setSearching] = useState(false);
  const [featuredOffer, setFeaturedOffer] = useState(null);
  const [locale, setLocale] = useState(router?.locale);
  const [historyView, setHistoryView] = useState(false);
  const [historyClick, setHistoryClick] = useState(false);

  const searchOffers = async () => {
    const searchResult = await apiRestGet(
      `${process.env.NEXT_PUBLIC_OFFER_EVENT_READ_SERVICE_URL}offer/all`,
      {
        cant_filas: 4,
        nombre: searchTerm,
        pagina: 0,
      }
    );

    setSearching(false);

    if (searchResult.data?.length === 0) setOffersList([]);

    if (searchResult.data?.ofertas) {
      setOffersList(searchResult.data.ofertas);

      if (searchResult.data?.ofertas.length > 0) {
        setFeaturedOffer(searchResult.data.ofertas[0]);
      }
    }
  };

  const closeBigSearchBox = () => {
    onBigSearchBoxClosed();
  };

  const registerSearch = async () => {
    let role = await getCurrentUserRole();
    let user = await getCurrentUser();
    if (role === 1) {
      if (searchTerm.trim() != '') {
        let response = await apiRestPost('generalData/registerHistorySearch', {
          id_usuario: user?.attributes?.email,
          search: searchTerm,
        });
      }
    }
  };
  useEffect(() => {
    if (searchTimeout !== null) clearTimeout(searchTimeout);

    if (searchTerm.length < MIN_TERM_LENGTH_FOR_SEARCH) {
      setOffersList([]);
      return;
    }

    setSearching(true);
    setSearchTimeout(setTimeout(searchOffers, 500));

    return () => clearTimeout(searchTimeout);
  }, [searchTerm]);

  useEffect(() => {
    closeBigSearchBox();
  }, [router?.asPath]);

  useEffect(() => {
    if (router.locale) {
      setLocale(router?.locale);
    }
  }, [router?.locale]);
  const t = useTranslations('BigSearchBox');
  return (
    <div
      className={classNames(
        styles.bigSearchBox,
        isOpened ? styles.opened : null
      )}
    >
      <button
        aria-label="Cerrar caja de búsqueda de servicios"
        className={classNames('btn iconOnly', styles.closeButton)}
        onClick={() => closeBigSearchBox()} style={{color: 'transparent'}}
      >.
        <Icon icon="close" size={30} />
      </button>

      <div className={styles.searchAndResults}>
        <div className={styles.searchInputContainer}>
          <button
            aria-label="Cerrar caja de búsqueda de servicios"
            className="btn iconOnly"
            onClick={() => closeBigSearchBox()} style={{color: 'transparent'}}
          >.
            <Icon icon="arrow" size={18} />
          </button>
          <label htmlFor="search-input">Receive promotional offers?</label>
          <input
            id="search-input"
            type="search"
            autoComplete="off"
            onChange={(e) => setSearchTerm(e.target.value)}
            onClick={(e) => setHistoryView(true)}
            value={searchTerm}
          />
          <Icon icon="search" size={40} />
          {historyView && searchTerm.trim() === '' && (
            <HistorySearch
              onClick={(e) => setSearchTerm(e.target.innerText)}
              event={false}
            />
          )}
        </div>

        {searchTerm.length >= MIN_TERM_LENGTH_FOR_SEARCH &&
          offersList.length > 0 && (
            <div className={styles.resultsAndPreview}>
              {offersList.length > 0 && (
                <div className={styles.results}>
                  <ul>
                    {offersList.map((offer, index) => {
                      return (
                        <li
                          onMouseEnter={(e) => setFeaturedOffer(offer)}
                          key={index}
                          onClick={(e) => registerSearch()}
                        >
                          <Link href={getServiceURL(offer.id_oferta)}>
                            <a href="">
                              <div className={styles.imageContainer}>
                                <Image
                                  alt={offer.nombre}
                                  src={getImageURL(
                                    offer.imagenes[0]?.ImagenURL ?? ''
                                  )}
                                  layout="fill"
                                  objectFit="cover"
                                />
                              </div>
                              <div className={styles.content}>
                                <p>{splitField(offer.nombre, locale)}</p>
                                <p>
                                  {`${getDepartmentName(
                                    getDepartmentByMunicipality(
                                      getTouristServiceTypeMuncipalityID(offer),
                                      generalConfig?.ciudad,
                                      generalConfig?.departamento
                                    )?.id_departamento,
                                    generalConfig?.departamento
                                  )}, ${getMunicipalityName(
                                    getTouristServiceTypeMuncipalityID(offer),
                                    generalConfig?.ciudad
                                  )}`}
                                </p>
                              </div>
                            </a>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>

                  <Link href="/busqueda">
                    <a href="">
                      {t('allResults')} <Icon icon="arrow" size={13} />
                    </a>
                  </Link>
                </div>
              )}

              {featuredOffer !== null && (
                <div className={styles.preview}>
                  <div className={styles.imageContainer}>
                    <Image
                      alt={splitField(featuredOffer.nombre, locale)}
                      src={getImageURL(
                        featuredOffer.imagenes[0]?.ImagenURL ?? ''
                      )}
                      layout="fill"
                      objectFit="cover"
                    />
                  </div>

                  <div className={styles.content}>
                    <p>{splitField(featuredOffer.nombre, locale)}</p>
                    <p>
                      {`${getDepartmentName(
                        getDepartmentByMunicipality(
                          getTouristServiceTypeMuncipalityID(featuredOffer),
                          generalConfig?.ciudad,
                          generalConfig?.departamento
                        )?.id_departamento,
                        generalConfig?.departamento
                      )}, ${getMunicipalityName(
                        getTouristServiceTypeMuncipalityID(featuredOffer),
                        generalConfig?.ciudad
                      )}`}
                    </p>
                  </div>

                  <Link href={`/servicio/${featuredOffer.id_oferta}`}>
                    <a href="">
                      {t('goToService')} <Icon icon="arrow" size={13} />
                    </a>
                  </Link>
                </div>
              )}
            </div>
          )}

        {searchTerm.length >= MIN_TERM_LENGTH_FOR_SEARCH &&
          offersList.length === 0 &&
          !searching && (
            <div className={styles.notFound}>
              <p>
                No tenemos resultados para <span>{`"${searchTerm}"`}</span>
              </p>
              <p>Quizás podría interesarte</p>
              <ul>
                <li>
                  <a href="">
                    <Icon icon="arrow" size={12} /> Hotel
                  </a>
                </li>

                <li>
                  <a href="">
                    <Icon icon="arrow" size={12} /> Hostel
                  </a>
                </li>

                <li>
                  <a href="">
                    <Icon icon="arrow" size={12} /> Camping
                  </a>
                </li>
              </ul>
            </div>
          )}
      </div>
    </div>
  );
};

export async function getStaticProps({ locale }) {
  const response = await import(`../lang/${locale}.json`);

  return {
    props: {
      lang: response.default,
    },
  };
}
