import Axios from 'axios';
import { Auth } from 'aws-amplify';
const WebNotificationsUrl = 'notification-Web/pst/list'; // endpoint de búsqueda para las notificaciones para el PST
const WebTouristNotificationsUrl = 'notification-Web/turista/list'; // endpoint de búsqueda para las notificaciones para el Turista
const WebReadNotificationUrl = 'notification-Web/readNotification'; // endpoint de búsqueda para las notificaciones para el turista
const WebReadNotificationUrlPst = 'notification-Web/readNotificationPst'; // endpoint de búsqueda para las notificaciones para el PST
const WebCorroNotificationUrl = 'notification-Web/correo/pedidos'; // endpoint de correo para los pedidos para el PST
const WebCorroNotificationVenta = 'notification-Web/correo/compra'; // endpoint de correo para los pedidos para el PST
const WebCreateNotificationUrlPST = 'notification-Web';

async function getRequestHeaders() {
  try {
    const currentSession = await Auth.currentSession();

    return {
      Authorization: 'Bearer ' + currentSession.getAccessToken().getJwtToken(),
      'twb-cog': currentSession.getIdToken().getJwtToken(),
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
    };
  } catch (error) {
    return {};
  }
}

//function para la búsqueda de las notificaciones para el PST
export async function apiNotificationGet(body: any): Promise<any> {
  try {
    const requestOptions: any = {};
    requestOptions.headers = await getRequestHeaders();
    const response = await Axios.post(
      process.env.NEXT_PUBLIC_URL_BACK_NOTIFICATION + WebNotificationsUrl,
      body,
      requestOptions
    );

    return response.data;
  } catch (error) {
    return { error: error };
  }
}
//function para crear la notificacion del PST
export async function apiNotificationPost(body: any): Promise<any> {
  try {
    const requestOptions: any = {};
    requestOptions.headers = await getRequestHeaders();
    const response = await Axios.post(
      process.env.NEXT_PUBLIC_URL_BACK_NOTIFICATION +
        WebCreateNotificationUrlPST,
      body,
      requestOptions
    );

    return response.data;
  } catch (error) {
    return { error: error };
  }
}

//function para la búsqueda de las notificaciones para el turista
export async function apiNotificationGetTourist(body: any): Promise<any> {
  try {
    const requestOptions: any = {};
    requestOptions.headers = await getRequestHeaders();
    const response = await Axios.post(
      process.env.NEXT_PUBLIC_URL_BACK_NOTIFICATION +
        WebTouristNotificationsUrl,
      body,
      requestOptions
    );
    return response.data;
  } catch (error) {
    return { error: error };
  }
}

//function para poner las notificaciones leidas
export async function readNotification(body: any): Promise<any> {
  try {
    const requestOptions: any = {};
    requestOptions.headers = await getRequestHeaders();
    const response = await Axios.post(
      process.env.NEXT_PUBLIC_URL_BACK_NOTIFICATION + WebReadNotificationUrl,
      body,
      requestOptions
    );
    return response.data;
  } catch (error) {
    return { error: error };
  }
}
//function para poner las notificaciones leidas
export async function readNotificationPst(body: any): Promise<any> {
  try {
    const requestOptions: any = {};
    requestOptions.headers = await getRequestHeaders();
    const response = await Axios.post(
      process.env.NEXT_PUBLIC_URL_BACK_NOTIFICATION + WebReadNotificationUrlPst,
      body,
      requestOptions
    );
    return response.data;
  } catch (error) {
    return { error: error };
  }
}

export async function CorreosPedidos(idOferta:number,idTurista:number,codigo:string,transferencia:string,camtida:number,precio:number): Promise<any> {
  try {
    const requestOptions: any = {};
    requestOptions.headers = await getRequestHeaders();
    const response = await Axios.post(
      process.env.NEXT_PUBLIC_URL_BACK_NOTIFICATION + WebCorroNotificationUrl,
      { id_oferta: idOferta, id_turista: idTurista, codigo: codigo, transferencia: transferencia,camtida:camtida,precio:precio },
      requestOptions
    );
    return response.data;
  }catch (error) {
    return { error: error };
  }
}


export async function CorreosVentas(idOferta:number,idTurista:number,codigo:string,transferencia:string,camtida:number,precio:number): Promise<any> {
  try {
    const requestOptions: any = {};
    requestOptions.headers = await getRequestHeaders();
    const response = await Axios.post(
      process.env.NEXT_PUBLIC_URL_BACK_NOTIFICATION + WebCorroNotificationVenta,
      { id_oferta: idOferta, id_turista: idTurista, codigo: codigo, transferencia: transferencia,camtida:camtida,precio:precio },
      requestOptions
    );
    return response.data;
  }catch (error) {
    return { error: error };
  }
}
