import Image from 'next/image';
import styles from '../../styles/components/admin-header.module.scss';
import Icon from '../base/icon';
import classNames from 'classnames';
import mobileMenuStyles from '../../styles/molecules/mobile-menu.module.scss';
import { FunctionComponent, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getCurrentUser, signOut } from '../../services/cognito';
import Link from 'next/link';
import { ADMIN_MENU_OPTIONS } from '../../shared/constants';

type adminProps = {
  changeEmail?: string;
};

export const AdminHeader: FunctionComponent<adminProps> = ({
  changeEmail
}
) => {
  const router = useRouter();
  const [currentUser, setCurrentUser] = useState(null);

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const signOutAdmin = async () => {
    await signOut();
    await router.replace('/admin/login');
  };


  useEffect(() => {
    (async () => {
      setCurrentUser(await getCurrentUser());
    })();
  }, [changeEmail])

  return (
    <div className={styles.headerContainer} role={'navigation'}>
      <div className={styles.header}>
        <div className={styles.logoAndTitle}>
          <a href='/' className={styles.logo}>
            <Image
              alt="Logo Pacifico Colombia"
              src="/images/logo-pacifico-colombia-white.svg"
              layout="fill"
            />
          </a>
          <p>Administración</p>
        </div>

        {currentUser?.attributes && (
          <div className={styles.logoutAndMobileMenu}>
            <button
              aria-label="Mostrar u ocultar el menú en móviles"
              className="btn iconOnly"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              <Icon icon="menu" size={19} />
            </button>

            <div className={styles.desktopLogout}>
              <p>{currentUser?.attributes?.email}</p>
              <button onClick={signOutAdmin}>Cerrar sesión</button>
            </div>
          </div>
        )}
      </div>

      <div
        className={classNames(
          mobileMenuStyles.mobileMenu,
          showMobileMenu ? mobileMenuStyles.opened : null
        )}
      >
        <ul>
          {ADMIN_MENU_OPTIONS.map((option, key) => {
            return (
              <li key={key}>
                <Link href={option.path}>
                  <a href="">
                    <Icon icon={option.icon} size={20} />
                    <p>{option.label}</p>
                  </a>
                </Link>
              </li>
            );
          })}
          <li>
            <button onClick={signOutAdmin}>
              <Icon icon="sign-out" size={20} />
              <p>Cerrar sesión</p>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};
