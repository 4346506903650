import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from '../../styles/components/modal.module.scss';
import Icon from './icon';
import classNames from 'classnames';

type ModalProps = {
  active?: boolean;
  children;
  onClose;
  title: string;
  className?: string;
};

const Modal: FunctionComponent<ModalProps> = ({
  active = false,
  children,
  onClose,
  title,
  className,
}) => {
  const [isOpen, changeBoxVisibility] = useState(active);

  const handleClose = () => {
    changeBoxVisibility(false);
    onClose?.();
  };

  //useEffect(()=>{
  //  document.querySelector("body").style.overflow = 'hidden'
  //  return ()=> {
  //    document.querySelector("body").style.overflow = 'visible'
  //  }
  //},[])

  useEffect(() => {
    changeBoxVisibility(active);
  }, [active]);

  return (
    <div
      className={classNames(styles.modalContainer)}
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <div className={classNames(styles.box, className)}>
        <button
          className={classNames('btn iconOnly', styles.closeButton)}
          onClick={handleClose}
        >
          <Icon icon="close" size={17} />
        </button>

        <div className={styles.modalContent}>
          {title && <h1 className={styles.title}>{title} </h1>}

          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
