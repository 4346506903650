import React, { useContext, useEffect, useState } from 'react';
import styles from '../../styles/components/notification-button-nav.module.scss';
import { UserContext } from '../user-provider';
import {
  apiNotificationGetTourist,
  readNotification,
} from '../../services/requestNotificacion';
import { apiRestPost } from '../../services/request';
import { useTranslations } from 'next-intl';

const EstadoNotificacion = {
  leida: 3,
  notLeida: 2,
};

type notification = {
  titulo: string;
  descripcion: string;
  id_evento: number | null;
  id_oferta: number | null;
  id_notificacion_promocional_turista: number;
  estado_notificacion: number;
};

export default function NotificationButtonNav() {
  const t = useTranslations('Header');
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [offerIsPublished, setOfferIsPublished] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<notification[] | []>([]);
  const { user } = useContext(UserContext);

  const searchNotifications = () => {
    apiNotificationGetTourist({ id_turista: user.info.perfil.id_turista }).then(
      (response) => {
        if (response.status === 200) {
          setNotifications(response.data);
        }
      }
    );
    apiRestPost('/user/disableOrEnableNotifications', {
      id_turista: user.info.perfil.id_turista,
    }).then((response) => {
      if (response.status === 200) {
        setOfferIsPublished(response.data != 0);
      }
    });
  };

  const disableNotifications = (disable: boolean) => {
    apiRestPost('/user/disableOrEnableNotifications', {
      id_turista: user.info.perfil.id_turista,
      estado: !disable ? 1 : 0,
    }).then((response) => {
      if (response.status === 200) {
        setOfferIsPublished(!disable);
      }
    });
  };

  const notificationSet = (noti: notification) => {
    readNotification({
      id: noti.id_notificacion_promocional_turista,
      estado: EstadoNotificacion.leida,
    }).then();
  };

  const selectUrl = (notification: notification): string => {
    if (notification.id_evento !== null) {
      return '/eventos/' + notification.id_evento;
    }

    if (notification.id_oferta !== null) {
      return '/servicio/' + notification.id_oferta;
    }
  };

  useEffect(() => {
    if (user.info) {
      searchNotifications();
    }
  }, [user]);

  return (
    <>
      <span className={styles.notification}>
        <a
          onClick={() =>
            showNotifications
              ? setShowNotifications(false)
              : setShowNotifications(true)
          }
        >
          <img src="/images/svg/icon-7.svg" alt="" />
          {notifications.length > 0 ? (
            <img
              style={{
                position: 'absolute',
                paddingLeft: '8px',
                marginTop: '-10px',
              }}
              src="/images/svg/puntoAmarillo.svg"
              alt=""
            />
          ) : null}
          <span className={styles.textResponsive}>{t('notificaciones')}</span>
        </a>
        <div
          style={{ visibility: showNotifications ? 'visible' : 'hidden' }}
          className={styles.dr_menu}
        >
          <div className={styles.titulo}>
            <div>
              <p>{t('notificaciones')}</p>
            </div>
            <div>
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={offerIsPublished}
                  onChange={() => disableNotifications(offerIsPublished)}
                />
                <span className="slider" />
              </label>
            </div>
          </div>
          <div className={styles.contenido}>
            <ul>
              {notifications.length === 0 && (
                <li
                  style={{
                    height: '70px',
                    margin: '0 8px',
                    display: 'flex',
                    alignItems: 'center',
                    border: 'none',
                  }}
                >
                  <p
                    style={{
                      fontWeight: '400',
                      fontSize: '16px',
                      lineHeight: '20px',
                      color: '#24252e',
                    }}
                  >
                    No tienes notificaciones
                  </p>
                </li>
              )}
              {notifications.map((notificacion) => {
                return (
                  <li
                    className={
                      notificacion.estado_notificacion === 3
                        ? styles.disable
                        : ''
                    }
                  >
                    <a
                      href={selectUrl(notificacion)}
                      onClick={() => notificationSet(notificacion)}
                    >
                      <div>
                        <div>
                          {notificacion.estado_notificacion ===
                          EstadoNotificacion.notLeida ? (
                            <img src="/images/svg/Menu.svg" alt="Icono menu" />
                          ) : (
                            <img
                              src="/images/svg/Menu-gris.svg"
                              alt="Icono menu"
                            />
                          )}
                          <span>{notificacion.titulo}</span>
                        </div>
                        <span>{notificacion.descripcion}</span>
                      </div>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </span>
    </>
  );
}
