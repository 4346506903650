import React, { useEffect, useState } from 'react';
import Image from 'next/image';

// This component rendes a button with an optional icon inside
export default function Button(props) {
  const [classes, setClasses] = useState(
    props.className ? 'btn ' + props.className : 'btn'
  );
  const iconsData = {
    close: {
      src: '/icons/close.svg',
      width: '20px',
      height: '20px',
    },
    mail: {
      src: '/icons/mail.svg',
      width: '24px',
      height: '16,8px',
    },
    facebook: {
      src: '/icons/facebook.svg',
      width: '24px',
      height: '24px',
    },
    google: {
      src: '/icons/google.svg',
      width: '24px',
      height: '24px',
    },
  };
  const [currentIcon, setCurrentIcon] = useState(
    props.icon ? iconsData[props.icon] : null
  );

  useEffect(() => {
    setClasses('btn ' + props.className);
  }, [props.className]);
  useEffect(() => {
    setCurrentIcon(iconsData[props.icon]);
  }, [props.icon]);

  return (
    <button
      onClick={props.onClick}
      className={classes}
      disabled={props.disabled ? props.disabled : false}
    >
      {currentIcon && (
        <Image
          alt="Icon"
          src={currentIcon.src}
          width={currentIcon.width}
          height={currentIcon.height}
          loading="lazy"
        />
      )}
      {props.children}
    </button>
  );
}
