import styles from "../../styles/components/language-selection.module.scss";
import { useRouter } from 'next/router';

export default function LanguageSelection() {

  const router = useRouter();
  const { pathname, asPath, query } = router;

  const changeLanguage = (newLocale: string) => {
    router.push({ pathname, query }, asPath, { locale: newLocale });
  };
  return (
    <div className={styles.languageSelection}>
      <button
        onClick={() => changeLanguage('es')}
        className={router.locale === 'es' ? styles.active : null}
      >
        Español
      </button>
      <span />
      <button
        onClick={() => changeLanguage('en')}
        className={router.locale === 'en' ? styles.active : null}
      >
        English
      </button>
    </div>
  );
}
