import styles from "../../../styles/components/dropdown.module.scss";
import { useState } from "react";
import UserImageContainer from "../user-image-container";
import { useTranslations } from 'next-intl';


type DropdownType = {
    children: any,
    name: string,
    className: string,
    user?: any,
    type?: string,
}


export default function DropDown({ children, name, className, user, type }: DropdownType) {
    const t = useTranslations('Header');
    const [open, setOpen] = useState<Boolean>(false);

    return <>
        <div className={styles.dropdowncontainer}>
            <button className={className} onClick={() => setOpen(!open)}>
                <p>{name}</p>
                {type && <UserImageContainer
                    imageName={user.info?.perfil?.imagen}
                    type="header"
                />}
            </button>
            {open && children}
        </div>


    </>
}