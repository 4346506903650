import { itemMenuNotification } from "./interfacesMenuNotificaciones";
import { NOTIFICATION_TYPE } from "../../../shared/constants"
import {splitField, splitFieldForms} from "../../../shared/utils";
import React from "react";
import {readNotificationPst} from "../../../services/requestNotificacion";

const month = {
  0: 'ENE',
  1: 'FEB',
  2: 'MAR',
  3: 'ABR',
  4: 'MAY',
  5: 'JUN',
  6: 'JUL',
  7: 'AGO',
  8: 'SET',
  9: 'OCT',
  10: 'NOV',
  11: 'DIC',
}

const divStyle = {
  padding: '8px',
  borderBottom: '1px solid #D3D4DD'
}

const titleStyle = {
  fontWeight: '500',
  fontsize:'15px',
  color:'#1b629d',
  lineHeight: '19px',
  letterSpacing:'-0.0025em'
}

const textStyle = {
  fontWeight: '400',
  fontsize:'16px',
  color:'#24252E',
  lineHeight: '20px',
  letterSpacing:'-0.0025em'
}

const dateStyle ={
  fontWeight: '400',
  fontsize:'15px',
  color:'#515162',
  lineHeight: '19px',
  letterSpacing:'-0.0025em'
}

const ItemMenuNotification = (datos:itemMenuNotification) => {
  const date = new Date(datos.reservationDate);

  const sendRead = async () => {
    const body = {
      estado: 1,
      id: datos.id
    }
    const read = await readNotificationPst(body)
  }

  if (datos.notificationType === NOTIFICATION_TYPE.Evento){
    return <>
      <li>
        <a href={'/negocios/ofertas/eventos-cercanos?offerID='+datos.idOferta} onClick={()=> sendRead()}>
          <div style={divStyle}>
            { datos.wellRead === 1 ? (<img src="/images/svg/Menu-gris.svg" alt="Icono menu" />) : (<img src="/images/svg/Menu.svg" alt="Icono menu" />) }

            <span style={titleStyle}>
              Nuevo Evento ha sido creado
            </span>
            <br/>
            <span style={textStyle}>
              El servicio <b>{ splitFieldForms(datos.serviceName).spanish }</b> puede ser asociado al evento
            </span>
            <br/>
          </div>
        </a>
      </li>
    </>
  }

  if (datos.notificationType === NOTIFICATION_TYPE.Reserva){
    return <>
      <li>
        <a href={'/negocios/reservas/'+datos.idReservation} onClick={()=> sendRead()}>
          <div style={divStyle}>
            { datos.wellRead === 1 ? (<img src="/images/svg/Menu-gris.svg" alt="Icono menu" />) : (<img src="/images/svg/Menu.svg" alt="Icono menu" />) }
            <span style={titleStyle}>
              Nueva reserva
            </span>
            <br/>
            <span style={textStyle}>
              El servicio <b>{ splitFieldForms(datos.serviceName).spanish }</b> a sido comprado por <b>{ datos.clientName }</b>
            </span>
            <br/>
            <span style={dateStyle}>
              { date.getDate() } { month[date.getMonth()] } { date.getFullYear() } - { date.getHours()} : { date.getMinutes() }
            </span>
          </div>
        </a>
      </li>
    </>
  }

  if (datos.notificationType === NOTIFICATION_TYPE.RNT){
    return <>
      <li>
        <a href={'#'} onClick={()=> sendRead()}>
          <div style={divStyle}>
            { datos.wellRead === 1 ? (<img src="/images/svg/Menu-gris.svg" alt="Icono menu" />) : (<img src="/images/svg/Menu.svg" alt="Icono menu" />) }
            <span style={titleStyle}>
              Aprobación de RNT
            </span>
            <br/>
            <span style={textStyle}>
              Tu <b>RNT</b> Fue Aprobado
            </span>
          </div>
        </a>
      </li>
    </>
  }

}

export default ItemMenuNotification;
