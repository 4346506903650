import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { apiRestGet } from '../../services/request';
import styles from '../../styles/components/historySearch.module.scss';
import { UserContext } from '../user-provider';
import Icon from './icon';

type historySearchProps = {
  onClick?: any;
  event?: boolean;
};

const HistorySearch: FunctionComponent<historySearchProps> = ({
  onClick,
  event = false,
}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const { user } = useContext(UserContext);

  const getSearchData = async () => {
    let response = await apiRestGet('generalData/getHistorysearch', {
      id_usuario: user?.info?.perfil?.id_usuario,
      event: event,
    });
    if (response?.data) {
      setFilteredSuggestions(response?.data);
    }
  };
  useEffect(() => {
    if (user?.info?.perfil?.id_turista) {
      getSearchData();
    }
  }, [user, event]);
  return (
    <>
      {filteredSuggestions.length > 0 && (
        <>
          <ul className={event ? styles.suggestionsEvent : styles.suggestions}>
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              //   if (index === activeSuggestion) {
              //     className = "suggestion-active";
              //   }
              return (
                <li
                  className={className}
                  key={suggestion?.detail}
                  onClick={onClick}
                >
                  <p>
                    {event
                      ? suggestion?.detail?.slice(0, 40)
                      : suggestion?.detail?.slice(0, 120)}{' '}
                    {suggestion?.detail?.length > (event ? 55 : 120) && '...'}
                  </p>
                  <Icon icon="clock" size={20} />
                </li>
              );
            })}
          </ul>
        </>
      )}
    </>
  );
};

export default HistorySearch;
