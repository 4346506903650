import styles from '../../styles/components/admin-footer.module.scss';

const AdminFooter = () => {
  const currentDate = new Date();

  return (
    <footer className={styles.footer}>
      <span>
        {`© Todos los derechos reservados ${currentDate.getFullYear()} - Turismo Región Pacífico`}
      </span>
    </footer>
  );
};

export default AdminFooter;
