import styles from '../../styles/components/notification-box.module.scss';
import classNames from 'classnames';
import Icon from './icon';
import { FunctionComponent } from 'react';

type NotificationBoxProps = {
  className?: string;
  style?: any;
  text: string;
};

const NotificationBox: FunctionComponent<NotificationBoxProps> = ({
  className,
  style,
  text,
}) => {
  const classes = [styles.notificationBox];

  if (className) {
    classes.push(className);
  }

  return (
    <div className={classNames(classes)} style={style}>
      <Icon icon="ok" size={22} />
      <p>{text}</p>
    </div>
  );
};

export default NotificationBox;
