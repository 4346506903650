import styles from '../../styles/molecules/user-image-container.module.scss';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { getImageURL } from '../../shared/utils';

export default function UserImageContainer(props) {
  const containerClasses = [styles.userImageContainer];
  const [imageUrl, setImageUrl] = useState('/images/default-avatar.svg');

  switch (props.type) {
    case 'header':
      containerClasses.push(styles.inHeader);
      break;
    case 'profile':
      containerClasses.push(styles.inProfile);
      break;
  }

  if (props.className) {
    containerClasses.push(props.className);
  }

  useEffect(() => {
    if (props.src) {
      setImageUrl(props.src);
    } else if (props.imageName) {
      setImageUrl(getImageURL(props.imageName));
    }
  }, [props.src, props.imageName]);

  return (
    <div className={classNames(containerClasses)}>
      <img src={imageUrl} alt="Foto del usuario" />
    </div>
  );
}
