import styles from '../styles/components/header-basic.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslations } from 'next-intl';

export default function HeaderBasic(props) {
  const t = useTranslations('Header');
  return (
    <div className={styles.headerBasic} >
      <Link href="/">
        <a href="" className={styles.logoContainer}>
          <Image
            alt="Logo Pacifico Colombia"
            src={'/images/logo-pacifico-colombia.svg'}
            layout="fill"
          />
        </a>
      </Link>

      {props.showLoginButton !== false && (
        <div className="options">
          <Link href="/negocios/login">
            <a href="" className="btn primary">
              {t('login')}
            </a>
          </Link>
        </div>
      )}
    </div>
  );
}
