import { FunctionComponent, useEffect, useState } from 'react';
import styles from '../../styles/components/input-container.module.scss';
import Icon from './icon';
import classNames from 'classnames';
import Tooltip from './tooltip';
import { useRouter } from 'next/router';

type InputAndLabelProps = {
  className?: string;
  description?: string;
  errorMessage?: string;
  icon?: string;
  inputMode?: any;
  label?: string;
  max?: any;
  maxLength?: number;
  min?: any;
  name?: string;
  onBlur?: any;
  onChange?: any;
  onClick?: any;
  placeholder?: string;
  required?: boolean;
  restrictions?: string;
  showRequiredMark?: boolean;
  type?: string;
  value?: any;
  secondLabel?: string;
  disable?: boolean;
  tooltip?: boolean;
  tooltipMessage?: string;
};

const InputAndLabel: FunctionComponent<InputAndLabelProps> = ({
  className,
  description,
  errorMessage,
  icon,
  inputMode,
  label,
  max,
  maxLength,
  min,
  name,
  onBlur,
  onChange,
  onClick,
  placeholder,
  required,
  disable,
  restrictions,
  showRequiredMark,
  secondLabel,
  type,
  value,
  tooltip,
  tooltipMessage,
}) => {
  const router = useRouter();

  const [inputType, setInputType] = useState(type);
  const [classes, setClasses] = useState(
    classNames(styles.inputContainer, className)
  );

  const [characterCount, setCharacterCount] = useState(value?.length ?? 0);

  const togglePassword = (e) => {
    e.preventDefault();
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  const handleInputChange = (e) => {
    if (e.target.type === 'number') {
      if (e.target.value.length > 20) {
        e.target.value = value;
      }
    }
    if (maxLength) setCharacterCount(e.target.value.length);
    onChange?.(e);
  };

  const handleClickChange = (e) => {
    if (e < 0) return;
    onClick?.(e);
  };

  const handleKeyPressed = (event) => {
    var alphanumericRegex = /[a-zA-Z0-9]/;

    if (
      (inputType === 'number' && !isCharADigit(event.key)) ||
      (restrictions === 'alphanumeric' && !alphanumericRegex.test(event.key))
    ) {
      event.preventDefault();
    }
  };

  const isCharADigit = (char: string): boolean => {
    return Number(char) < 10;
  };

  useEffect(() => {
    if (className) {
      setClasses(classNames(classes, className));
    }
  }, [className]);

  useEffect(() => {
    if (errorMessage && errorMessage !== '') {
      setClasses(classNames(classes, styles.error));
    } else {
      setClasses(classNames(styles.inputContainer, className));
    }
  }, [errorMessage]);

  useEffect(() => {
    setInputType(type);
  }, [type]);

  const sty = {
    display: 'flex',
  };

  return (
    <div className={classes}>
      {label && (
        <label
          htmlFor={name}
          className={showRequiredMark ? 'required' : null}
          style={tooltip ? sty : null}
        >
          {label} &nbsp; {tooltip ? <Tooltip message={tooltipMessage} /> : null}
        </label>
      )}

      {description && <p className={styles.description}>{description}</p>}

      {inputType === 'textarea' && (
        <div>
          {secondLabel && (
            <>
              <p>{secondLabel}</p>
              <br />
            </>
          )}
          <textarea
            aria-label={name}
            id={name}
            defaultValue={value ?? ''}
            maxLength={maxLength}
            name={name}
            onChange={(e) => handleInputChange(e)}
            placeholder={placeholder}
            disabled={disable !== null ? disable : false}
          />

          {maxLength && (
            <p
              className={styles.characterCount}
            >{`${characterCount}/${maxLength}`}</p>
          )}
        </div>
      )}

      {inputType === 'input-number' && (
        <div className={styles.inputnumber}>
          <div className={styles.divcontainer}>
            <button
              disabled={disable !== null ? disable : false}
              onClick={(e) => handleClickChange(value - 1)}
            >
              -
            </button>
            <input
              aria-label={name}
              id={name}
              defaultValue={value ?? ''}
              maxLength={maxLength}
              name={name}
              min={min}
              onChange={(e) => handleInputChange(e)}
              placeholder={placeholder}
              value={value ?? ''}
              type={'number'}
              disabled={disable !== null ? disable : false}
            />
            <button
              disabled={disable !== null ? disable : false}
              onClick={(e) => handleClickChange(value + 1)}
            >
              +
            </button>
          </div>
          {maxLength && (
            <p
              className={styles.characterCount}
            >{`${characterCount}/${maxLength}`}</p>
          )}
        </div>
      )}

      {inputType !== 'textarea' && inputType !== 'input-number' && (
        <div className={styles[type]}>
          {secondLabel && (
            <>
              <p>{secondLabel}</p>
              <br />
            </>
          )}
          <input
            aria-label={name}
            defaultValue={value ?? ''}
            inputMode={inputMode}
            id={name}
            max={max}
            min={min}
            maxLength={maxLength}
            name={name}
            onBlur={onBlur}
            onChange={(event) => handleInputChange(event)}
            onKeyPress={(event) => handleKeyPressed(event)}
            placeholder={placeholder}
            required={required}
            disabled={disable !== null ? disable : false}
            type={inputType}
            autoComplete="new-password"
          />

          {maxLength && (
            <p
              className={styles.characterCount}
            >{`${characterCount}/${maxLength}`}</p>
          )}

          {icon && <Icon icon={icon} size={23} />}

          {type && type === 'password' && inputType === 'password' && (
            <button onClick={togglePassword}>
              <Icon icon="eye" size={25} />
            </button>
          )}

          {type && type === 'password' && inputType === 'text' && (
            <button onClick={togglePassword}>
              <Icon icon="eye-2" size={23} />
            </button>
          )}
        </div>
      )}

      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
};

export default InputAndLabel;
